import { restApi } from '../../services/api';
import { createBasicRepository as createRepository } from 'elentari';
import { paginate, query } from '../../lib/feathers';
import addUploadCapabilities from '../../lib/addUploadCapabilities';

const props = {
  path: 'rusers',
  restApi,
  queryTransform: {
    username: query.like('username'),
    roles: query.like('roles'),
    roleId: query.in('roleId'),
    teamId: query.in('teamId'),
    id: query.in('id'),
    '$and[0][$or[0]][$role.label$]': query.in('$and[0][$or[0]][$role.label$]'),
    '$and[0][$or[1]][operating_segment]': query.in(
      '$and[0][$or[1]][operating_segment]',
    ),
    '$and[0][$or[1]][$role.label$]': query.in('$and[0][$or[1]][$role.label$]'),
    '$and[0][$role.label$]': query.in('$and[0][$role.label$]'),
    '$and[1][team_id]': query.in('$and[1][team_id]'),
    '$and[1][id]': query.in('$and[1][id]'),
    '$and[1][$or[0]][team_id]': query.in('$and[1][$or[0]][team_id]'),
    '$and[1][$or[1]][id]': query.in('$and[1][$or[1]][id]'),
    '$tenant.id$': query.in('$tenant.id$'),
    createdAt: query.inDay('createdAt'),
    ...paginate,
  },
};

const usersRepository = createRepository(props);
addUploadCapabilities(usersRepository);

export const usersSemPaginacaoRepository = createRepository({
  ...props,
  listResponsePath: {
    pathData: ['data'],
    pathCount: ['data', 'total'],
  },
});

export default usersRepository;
